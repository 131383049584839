import { Outlet, useLocation } from 'react-router-dom';

import BottomBar from './BottomBar';
import ControlBar, { ActionButton } from './ControlBar';
import PageHeader from './PageHeader';
import TopBar from './TopBar';

const getPageTitle = (pathname: string): string => {
  switch (pathname) {
    case '/dashboard':
      return 'Home';
    case '/dashboard/opportunities':
      return 'Opportunities';
    case '/dashboard/activity':
      return 'Activity';
    case '/dashboard/profile':
      return 'Profile';
    default:
      return '';
  }
};

const ApplicationLayout = () => {
  const location = useLocation();
  const title = getPageTitle(location.pathname);
  const showBackButton = location.pathname !== '/';

  return (
    <div className='flex min-h-screen flex-col'>
      <TopBar title={title} showBackButton={showBackButton} />
      <PageHeader title={title} />
      <div>
        <Outlet />
      </div>
      <ControlBar>
        <ActionButton
          label='Action'
          onClick={() => {
            /* handle click */
          }}
        />
        <ActionButton
          label='Skip'
          onClick={() => {
            /* handle click */
          }}
          variant='outlined'
        />
      </ControlBar>
      <BottomBar />
    </div>
  );
};

export default ApplicationLayout;
