interface PageHeaderProps {
  title: string;
}

const PageHeader = ({ title }: PageHeaderProps) => {
  return (
    <div className='p-4'>
      <h1 className='text-2xl font-bold'>{title}</h1>
    </div>
  );
};

export default PageHeader;
