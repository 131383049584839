import { Outlet } from 'react-router-dom';

const RootLayout = () => {
  return (
    <div data-id='root-layout' className='min-h-dvh overflow-clip'>
      {/* Navbar */}
      <Outlet />
    </div>
  );
};

export default RootLayout;
