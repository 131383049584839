import { AssessmentResultType } from './Assessment';

export enum AssessmentQuestionType {
  IMAGE_UPLOAD = 'image_upload',
  DOCUMENT_UPLOAD = 'document_upload',
  MULTI_IMAGE_UPLOAD = 'multi_image_upload',
  MULTI_DOCUMENT_UPLOAD = 'multi_document_upload',
  MULTI_SELECT = 'multi_select',
  SINGLE_SELECT = 'single_select',
  SINGLE_SELECT_LOOKUP = 'single_select_lookup',
  FREE_FORM = 'free_form',
  FREE_FORM_LARGE = 'free_form_large',
  YEAR = 'year',
  NUMBER = 'number',
  MULTI_RADIO_GROUP = 'multi_radio_group',
  SINGLE_RADIO_GROUP = 'single_radio_group',
}

export enum AssessmentQuestionCategory {
  GENERAL = 'general',
  ACCOUNT = 'account',
  STRUCTURE = 'structural',
  BILLING = 'billing/usage',
  BUILDING = 'building',
  ROOF = 'roof',
  HVAC = 'hvac',
  PLUG_LOAD = 'plug load',
  VEHICLE = 'vehicle',
  DER = 'DER',
  POOL = 'pool',
  OTHER = 'other',
}

export interface AssessmentQuestionOption {
  id: string;
  name: string;
  display: string;
  value: string;
  thumbnailUrl?: string;
}

export interface AssessmentQuestion {
  id: string;
  name: string;
  display: string;
  label?: string;
  headerHelperText?: string;
  footerHelperText?: string;
  lookupTable?: string;
  lookupColumn?: string;
  type: AssessmentQuestionType;
  category: AssessmentQuestionCategory;
  options: AssessmentQuestionOption[];
}

export interface AssessmentResultValue {
  id: string;
  value: string;
  index?: number;
}

export interface AssessmentResult {
  id: string;
  value: string;
  type: AssessmentResultType;
  assessmentQuestionId: string;
  values: AssessmentResultValue[];
}

export interface AssessmentTemplateContentQuestion {
  question_id: string;
  question_order: number;
  depends_on?: number;
  depends_on_value?: string | string[];
  filter_on_value?: number;
  helper_html?: string;
  question_group?: string;
}

export interface AssessmentTemplateContentSectionCover {
  header: string;
  icon: string;
  subheader: string;
  body: string;
  matters_text: string;
  recording_enabled: number;
  useCase: string | JSX.Element;
}

export interface AssessmentTemplateContentSectionSubSection {
  header: string;
  description: string;
  section_order: number;
  section_type?: 'photo_hunt';
  depends_on?: string;
  depends_on_value?: string;
  questions?: AssessmentTemplateContentQuestion[];
  core_data_fields?: string[];
  repeats_on?: string;
}

export interface AssessmentTemplateContentSection {
  section_name: string;
  section_order: number;
  section_cover: AssessmentTemplateContentSectionCover;
  subsections: AssessmentTemplateContentSectionSubSection[];
}

export interface AssessmentTemplateContent {
  template_details: {
    terms_enabled: number;
    terms_version: string;
    display_carousel: number;
    theme: string;
  };
  template_sections: AssessmentTemplateContentSection[];
}

export enum AssessmentStatus {
  STARTED = 'started',
  COMPLETE = 'completed',
  DEACTIVATED = 'deactivated',
}

export interface AssessmentDetails {
  userId: string;
  propertyId: string;
  assessmentId: string;
  assessmentStatus: AssessmentStatus;
  templateId: string;
  templateVersion: string;
  template: AssessmentTemplateContent;
  questions: Record<string, AssessmentQuestion>;
  results: Record<string, AssessmentResult>;
}
