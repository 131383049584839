interface FeatureFlag {
  name: string;
  enabled: boolean;
}

const features: FeatureFlag[] = [
  {
    name: 'dashboard',
    enabled: import.meta.env.DEV,
  },
];

export const isFeatureEnabled = (featureName: string): boolean => {
  const feature = features.find((f) => f.name === featureName);

  return feature?.enabled ?? false;
};
