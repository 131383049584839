import { AssessmentQuestion } from 'src/api';

export type LookupQuestion = AssessmentQuestion & {
  lookupTable: string;
  lookupColumn: string;
};

export const isLookupQuestion = (
  question: AssessmentQuestion
): question is LookupQuestion => {
  return !!question.lookupColumn && !!question.lookupTable;
};
