import clsx from 'clsx';
import { forwardRef, HTMLAttributes } from 'react';

type PageLayoutProps = HTMLAttributes<HTMLDivElement>;

const FullPageLayout = forwardRef<HTMLDivElement, PageLayoutProps>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={clsx('', className)} {...props} />;
  }
);

FullPageLayout.displayName = 'FullPageLayout';

export default FullPageLayout;
