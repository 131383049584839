import {
  ExclamationTriangleIcon,
  FaceSmileIcon,
} from '@heroicons/react/24/solid';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssessmentFormSection, AssessmentFormSectionContent } from './types';
import { AssessmentStatus, usePatchAssessment } from 'src/api';
import { useAssessmentContext } from 'src/contexts/AssessmentContext';
import AssessmentReviewAndSubmit from 'src/pages/Assessment/components/AssessmentForm/AssessmentReviewAndSubmit';

export const useAssessmentFormReviewAndSubmitSection = () => {
  const {
    details: { assessmentId },
  } = useAssessmentContext();
  const { mutateAsync: patchAssessment } = usePatchAssessment();
  const navigate = useNavigate();

  const getAssessmentFormAndSubmitSection = useCallback(
    (
      sectionNames: AssessmentFormSection[],
      contents: Record<string, AssessmentFormSectionContent>,
      lastSection?: AssessmentFormSection
    ): {
      sectionContent: AssessmentFormSectionContent;
      sectionName: AssessmentFormSection;
    } => {
      const missingItems = sectionNames.reduce((acc, c) => {
        acc += c.totalCount - c.answered;

        return acc;
      }, 0);

      const prevSection = lastSection ? contents[lastSection.id] : undefined;
      const prevSectionLastSubsection =
        prevSection?.subsections[prevSection.subsections.length - 1];

      return {
        sectionName: {
          id: 'summary',
          status: 'complete',
          percentage: 1,
          answered: 1,
          totalCount: 1,
          notAnsweredSectionsNames: [],
        },
        sectionContent: {
          idx: sectionNames.length.toString().padStart(2, '0'),
          title: 'Submit Assessment',
          canPrevSection: true,
          canNextSection: true,
          prevSection: lastSection?.id,
          prevSectionSearchParams:
            prevSection && prevSectionLastSubsection
              ? {
                  currentSection: prevSection.title,
                  currentSubsection:
                    prevSectionLastSubsection.section_order +
                    prevSectionLastSubsection.header +
                    (prevSectionLastSubsection.repeats_on ? '0' : ''),
                }
              : undefined,
          overrideNextButtonAction: () => {
            void patchAssessment({
              assessmentId,
              status: AssessmentStatus.COMPLETE,
            }).then(() => {
              navigate('/assessment-complete');
            });
          },
          nextButtonLabel: 'Submit',
          subsections: [],
          cover: {
            header: 'Review & Submit Your Assessment',
            iconComponent:
              missingItems > 0 ? ExclamationTriangleIcon : FaceSmileIcon,
            subheader:
              missingItems > 0
                ? `${missingItems} missing item${missingItems > 1 ? 's' : ''}`
                : 'All Done',
            body:
              missingItems > 0
                ? 'It looks like you might be missing some details. The more accurate you are the better your cost saving will be!'
                : "You're all set. Submit and get your energy report from Maiven",
            useCase: (
              <AssessmentReviewAndSubmit
                contents={contents}
                sectionNames={sectionNames}
              />
            ),
            // Fill in the rest of the object
            icon: '',
            matters_text: '',
            recording_enabled: 0,
          },
        },
      };
    },
    [assessmentId, navigate, patchAssessment]
  );

  return { getAssessmentFormAndSubmitSection };
};
