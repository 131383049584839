import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import AssessmentFormMultiImageUploadQuestion from './AssessmentFormMultiImageUploadQuestion';
import AssessmentFormSingleSelectLookup from './AssessmentFormSingleSelectLookup';
import { isLookupQuestion } from './AssessmentFormSingleSelectLookup/types';
import {
  AssessmentQuestion,
  AssessmentQuestionType,
  AssessmentResult,
  AssessmentResultType,
} from 'src/api';
import {
  useDeleteAssessmentQuestionResultValue,
  useGetAssessmentQuestionUpload,
  usePutAssessmentQuestionResult,
} from 'src/api/hooks/mutations';
import Checkboxes from 'src/components/Checkboxes';
import Input from 'src/components/Input';
import RadioGroup from 'src/components/RadioGroup';
import Select, { SelectItem } from 'src/components/Select';
import TextArea from 'src/components/TextArea';
import { useDebounce } from 'src/hooks/useDebounce';
import { isUserInputResult, uploadFileToUrl } from 'src/lib';

interface Props {
  assessmentId: string;
  questionId: string;
  question: AssessmentQuestion;
  result?: AssessmentResult;
  dependingQuestions: string[];
  resultIndex?: number;
  filterOnQuestion?: AssessmentQuestion;
  filterOnResult?: AssessmentResult;
}

const AssessmentFormQuestion: React.FC<Props> = ({
  assessmentId,
  questionId,
  question,
  result,
  dependingQuestions,
  resultIndex,
  filterOnQuestion,
  filterOnResult,
}) => {
  const formSchema = useMemo(
    () =>
      yup.object({
        [question.name]: yup.string().required('This field is required'),
        userInput: yup
          .string()
          .default('')
          .when(question.name, {
            is: (value: string) => value === 'USER_INPUT',
            then: (schema) => schema.required('Please specify'),
            otherwise: (schema) => schema.default(''),
          }),
      }),
    [question.name]
  );

  const {
    mutateAsync: putAssessmentQuestionResult,
    isPending: isSavingQuestionResult,
  } = usePutAssessmentQuestionResult();
  const {
    mutateAsync: deleteAssessmentQuestionResultValue,
    isPending: isDeletingResultValue,
  } = useDeleteAssessmentQuestionResultValue();
  const {
    mutateAsync: getAssessmentQuestionUpload,
    isPending: isGettingResultUploadUrls,
  } = useGetAssessmentQuestionUpload();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm<yup.InferType<typeof formSchema>>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      [question.name]: result?.value,
      userInput: result?.values[0]?.value,
    },
  });

  const fieldValue = watch(question.name);

  const needsUserInput = isUserInputResult(fieldValue);

  const debouncedPutAssessmentQuestionResult = useDebounce(
    async (data: Record<string, string>) => {
      const sendUserInput =
        isUserInputResult(data[question.name]) && data.userInput;

      await putAssessmentQuestionResult({
        assessmentId,
        questionId,
        value: data[question.name],
        dependingQuestionIds: dependingQuestions,
        type: AssessmentResultType.USER_PROVIDED,
        ...(sendUserInput
          ? {
              userInput: data.userInput,
              shouldRemoveUserInput: false,
            }
          : { shouldRemoveUserInput: true }),
      });

      if (!sendUserInput) {
        setValue('userInput', '');
      }
    },
    500
  );

  const onSubmit = (data: Record<string, string>) => {
    debouncedPutAssessmentQuestionResult(data);
  };

  const onRemoveFile = useCallback(
    async (idx: number) => {
      const resultId = result?.id;
      const resultValueId = result?.values[idx].id;

      if (!resultId || !resultValueId) return;

      await deleteAssessmentQuestionResultValue({
        assessmentId,
        questionId,
        resultId,
        resultValueId,
      });
    },
    [
      assessmentId,
      deleteAssessmentQuestionResultValue,
      questionId,
      result?.id,
      result?.values,
    ]
  );

  const onFiles = useCallback(
    async (files: File[]) => {
      const filesByName = files.reduce<Record<string, File>>((a, c) => {
        a[c.name] = c;

        return a;
      }, {});

      const fileUrlsData = await getAssessmentQuestionUpload({
        assessmentId,
        questionId,
        files: Array.from(files).map((file) => ({
          fileName: file.name,
          contentType: file.type,
        })),
      });

      for await (const fileData of fileUrlsData) {
        const file = filesByName[fileData.fileName];

        await uploadFileToUrl(file, fileData.url);
        await putAssessmentQuestionResult({
          assessmentId,
          questionId,
          dependingQuestionIds: dependingQuestions,
          value: AssessmentResultType.USER_PROVIDED,
          type: AssessmentResultType.USER_PROVIDED,
          userInput: fileData.path,
          shouldRemoveUserInput: false,
          overrideNewValue: fileData.getUrl,
          allowMultiple:
            question.type === AssessmentQuestionType.MULTI_IMAGE_UPLOAD,
        });
      }
    },
    [
      assessmentId,
      getAssessmentQuestionUpload,
      putAssessmentQuestionResult,
      dependingQuestions,
      questionId,
      question.type,
    ]
  );

  return (
    <form
      id={`${question.name}-form`}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col gap-3'
    >
      {question.headerHelperText && (
        <span
          className='wysiwyg'
          dangerouslySetInnerHTML={{ __html: question.headerHelperText }}
        ></span>
      )}

      <Controller
        key={question.name}
        name={question.name}
        // Ignoring to cover empty string case for result.value
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        defaultValue={result?.value ?? ''}
        control={control}
        render={({ field }) => {
          const fieldProps = {
            ['data-id']: question.name,
            label: question.display,
            ...field,
            errors,
            disabled: !!field.disabled || isSavingQuestionResult,
            onBlur: handleSubmit(onSubmit),
          };

          switch (question.type) {
            case AssessmentQuestionType.SINGLE_RADIO_GROUP:
              return (
                <RadioGroup
                  options={question.options.map((option) => ({
                    description: option.display,
                    value: option.value,
                    label: option.name,
                    thumbnail: option.thumbnailUrl,
                  }))}
                  {...fieldProps}
                />
              );
            case AssessmentQuestionType.SINGLE_SELECT:
              return (
                <Select
                  items={question.options.map<SelectItem>((option) => ({
                    label: option.display,
                    value: option.value,
                  }))}
                  placeholder='Choose an option...'
                  {...fieldProps}
                />
              );
            case AssessmentQuestionType.FREE_FORM_LARGE:
              return <TextArea autoComplete='off' {...fieldProps} />;
            case AssessmentQuestionType.FREE_FORM:
              return <Input autoComplete='off' {...fieldProps} />;
            case AssessmentQuestionType.YEAR:
            case AssessmentQuestionType.NUMBER:
              return <Input autoComplete='off' {...fieldProps} type='number' />;
            // Specific handling below
            case AssessmentQuestionType.MULTI_IMAGE_UPLOAD:
            case AssessmentQuestionType.IMAGE_UPLOAD:
            case AssessmentQuestionType.DOCUMENT_UPLOAD:
            case AssessmentQuestionType.MULTI_SELECT:
            case AssessmentQuestionType.SINGLE_SELECT_LOOKUP:
              return <></>;
            default:
              return (
                <div>
                  {question.display}
                  TODO: Handle type = {question.type}
                </div>
              );
          }
        }}
      />

      {needsUserInput && (
        <Input
          {...register('userInput')}
          data-id={`${question.name}-user-input`}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onBlur={handleSubmit(onSubmit)}
          autoComplete='off'
          errors={errors}
        />
      )}

      {AssessmentQuestionType.MULTI_SELECT === question.type && (
        <Checkboxes
          data-id={question.name}
          label={question.display}
          items={question.options.map<SelectItem>((option) => ({
            label: option.display,
            value: option.value,
          }))}
          disabled={
            isDeletingResultValue ||
            isSavingQuestionResult ||
            isGettingResultUploadUrls ||
            (!!result?.value &&
              result.value !== (AssessmentResultType.USER_PROVIDED as string))
          }
          values={result?.values ?? []}
          onUnChecked={(idx) => {
            const resultId = result?.id;
            const resultValueId = result?.values[idx].id;

            if (!resultId || !resultValueId) return;

            void deleteAssessmentQuestionResultValue({
              assessmentId,
              questionId,
              resultId,
              resultValueId,
            });
          }}
          onChecked={(value) => {
            void putAssessmentQuestionResult({
              assessmentId,
              questionId,
              dependingQuestionIds: dependingQuestions,
              value: AssessmentResultType.USER_PROVIDED,
              type: AssessmentResultType.USER_PROVIDED,
              allowMultiple: true,
              userInput: value,
            });
          }}
        />
      )}
      {AssessmentQuestionType.SINGLE_SELECT_LOOKUP === question.type &&
        isLookupQuestion(question) && (
          <AssessmentFormSingleSelectLookup
            assessmentId={assessmentId}
            questionId={questionId}
            question={question}
            result={result}
            dependingQuestions={dependingQuestions}
            resultIndex={resultIndex}
            filterOnQuestion={
              filterOnQuestion && isLookupQuestion(filterOnQuestion)
                ? filterOnQuestion
                : undefined
            }
            filterOnResult={filterOnResult}
            onChange={(value) => {
              void putAssessmentQuestionResult({
                assessmentId,
                questionId,
                dependingQuestionIds: dependingQuestions,
                value: AssessmentResultType.USER_PROVIDED,
                type: AssessmentResultType.USER_PROVIDED,
                allowMultiple: typeof resultIndex === 'number',
                userInput: value,
                resultIndex,
              });
            }}
          />
        )}
      {[
        AssessmentQuestionType.DOCUMENT_UPLOAD,
        AssessmentQuestionType.MULTI_IMAGE_UPLOAD,
        AssessmentQuestionType.IMAGE_UPLOAD,
      ].includes(question.type) && (
        <AssessmentFormMultiImageUploadQuestion
          data-id={question.name}
          label={question.label ?? question.name}
          description={question.display}
          accept='image/*,application/pdf'
          disabled={
            isDeletingResultValue ||
            isSavingQuestionResult ||
            isGettingResultUploadUrls ||
            (!!result?.value &&
              result.value !== (AssessmentResultType.USER_PROVIDED as string))
          }
          multiple={question.type === AssessmentQuestionType.MULTI_IMAGE_UPLOAD}
          onRemoveFile={onRemoveFile}
          files={result?.values.map((resultValue) => resultValue.value) ?? []}
          onFiles={onFiles}
          currentValue={result?.value}
          onValueChange={(value) => {
            void putAssessmentQuestionResult({
              assessmentId,
              questionId,
              dependingQuestionIds: dependingQuestions,
              value: value ?? AssessmentResultType.USER_PROVIDED,
              type: AssessmentResultType.USER_PROVIDED,
            });
          }}
        />
      )}

      {question.footerHelperText && (
        <span
          className='wysiwyg'
          dangerouslySetInnerHTML={{ __html: question.footerHelperText }}
        ></span>
      )}
    </form>
  );
};

export default AssessmentFormQuestion;
