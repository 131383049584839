import { ReactNode } from 'react';

interface ControlBarProps {
  children: ReactNode;
}

interface ActionButtonProps {
  label: string;
  onClick: () => void;
  variant?: 'filled' | 'outlined';
}

export const ActionButton = ({
  label,
  onClick,
  variant = 'filled',
}: ActionButtonProps) => {
  const baseStyles = 'px-4 py-2 rounded-md text-sm transition-colors';
  const variantStyles =
    variant === 'filled'
      ? 'bg-black text-white hover:bg-gray-800'
      : 'border border-black text-black hover:bg-gray-50';

  return (
    <button onClick={onClick} className={`${baseStyles} ${variantStyles}`}>
      {label}
    </button>
  );
};

const ControlBar = ({ children }: ControlBarProps) => {
  return (
    <div className='fixed bottom-[56px] left-0 right-0 flex items-center justify-center gap-2 bg-white p-3'>
      {children}
    </div>
  );
};

export default ControlBar;
