import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface TopBarProps {
  title: string;
  showBackButton?: boolean;
  showCloseButton?: boolean;
}

const TopBar = ({ title, showBackButton, showCloseButton }: TopBarProps) => {
  return (
    <div className='flex h-14 items-center border-b bg-white px-4'>
      {showBackButton ? (
        <button className='p-2' onClick={() => window.history.back()}>
          <ArrowLeftIcon className='h-5 w-5' />
        </button>
      ) : (
        <div className='w-10' /> /* Spacer to balance the close button */
      )}

      <div className='flex-1 text-center'>
        <h1 className='text-base font-bold'>{title}</h1>
      </div>

      {showCloseButton ? (
        <button className='p-2' onClick={() => window.close()}>
          <XMarkIcon className='h-5 w-5' />
        </button>
      ) : (
        <div className='w-10' /> /* Spacer to balance the back button */
      )}
    </div>
  );
};

export default TopBar;
