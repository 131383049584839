import {
  HomeIcon,
  ListBulletIcon,
  UserIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/solid';
import { useLocation, Link } from 'react-router-dom';

const navItems = [
  { icon: HomeIcon, label: 'Home', path: '' },
  { icon: CurrencyDollarIcon, label: 'Opportunities', path: 'opportunities' },
  { icon: ListBulletIcon, label: 'Activity', path: 'activity' },
  { icon: UserIcon, label: 'Profile', path: 'profile' },
];

const BottomBar = () => {
  const location = useLocation();

  return (
    <nav className='fixed bottom-0 w-full border-t border-gray-200 bg-white'>
      <div className='flex items-center justify-around py-2'>
        {navItems.map(({ icon: Icon, label, path }) => {
          const isActive = location.pathname === path;

          return (
            <Link
              key={path}
              to={path}
              className={`flex flex-col items-center gap-0.5 ${
                isActive ? 'text-purple-500' : 'text-gray-700'
              }`}
            >
              <Icon className='h-5 w-5' />
              <span className='text-sm'>{label}</span>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default BottomBar;
