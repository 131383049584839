import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTagManager } from 'react-gtm-ts';

import App from 'src/App.tsx';
import 'src/index.css';

ReactTagManager.init({
  code: import.meta.env.VITE_GTM_ID, // GTM Code
  debug: false, // debug mode (default false)
  performance: false, // starts GTM only after user interaction (improve initial page load)
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
