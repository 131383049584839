import { Radio, RadioGroup as RadioGroupComponent } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { forwardRef } from 'react';

import FormFieldLabel from 'src/components/FormFieldLabel';
import Text from 'src/components/Typography';

export interface RadioGroupOption {
  label: string;
  description: string;
  thumbnail?: string;
  value: string;
}

export interface RadioGroupProps {
  ['data-id']: string;
  options: RadioGroupOption[];
  label?: string;

  // Form Controls
  value: string;
  disabled?: boolean;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
}

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ options, label, 'data-id': _dataId, ...props }, ref) => {
    const dataId = _dataId ?? props.name ?? 'radio-group';

    return (
      <div ref={ref}>
        <FormFieldLabel data-id={dataId} label={label} />
        <RadioGroupComponent
          value={props.value}
          onChange={(e) => {
            props.onChange(e);
            props.onBlur();
          }}
          disabled={props.disabled}
          id={`${dataId}-container`}
          className={clsx(
            'grid grid-cols-1 gap-2 data-[disabled]:opacity-50 sm:grid-cols-3',
            !!label && 'mt-2'
          )}
        >
          {options.map((option) => (
            <Radio
              key={option.value}
              value={option.value}
              aria-label={option.label}
              className={clsx(
                'group relative flex cursor-pointer rounded-md bg-gray-50 shadow-sm',
                'focus-input outline outline-1 outline-gray-300'
              )}
            >
              {option.thumbnail && (
                <img
                  src={option.thumbnail}
                  alt={option.label}
                  className='w-20 rounded-l-lg object-cover'
                />
              )}
              <span
                className={clsx(
                  'my-4 flex flex-1',
                  option.thumbnail ? 'ml-3' : 'ml-4'
                )}
              >
                <span className='flex flex-col gap-1'>
                  <Text size='base' className='block'>
                    {option.label}
                  </Text>
                  <Text size='sm' className='text-gray-500'>
                    {option.description}
                  </Text>
                </span>
              </span>
              <CheckCircleIcon
                aria-hidden='true'
                className='my-4 mr-4 h-5 w-5 [.group:not([data-checked])_&]:invisible'
              />
              <span
                aria-hidden='true'
                className={clsx(
                  'pointer-events-none absolute -inset-px rounded-lg',
                  'border-0 outline outline-2 outline-transparent group-data-[checked]:outline-purple-600'
                )}
              />
            </Radio>
          ))}
        </RadioGroupComponent>
      </div>
    );
  }
);

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
