import { HomeIcon } from '@heroicons/react/24/outline';
import {
  CameraIcon,
  UserCircleIcon,
  QuestionMarkCircleIcon,
  LightBulbIcon,
} from '@heroicons/react/24/solid';
import { useMemo, useState } from 'react';

import {
  AssessmentFormSectionStatus,
  AssessmentFormSection,
  AssessmentFormSectionContentByName,
} from './types';
import { useAssessmentFormReviewAndSubmitSection } from './useAssessmentFormReviewAndSubmitSection';
import {
  AssessmentResult,
  AssessmentTemplateContentSection,
  AssessmentTemplateContentSectionSubSection,
} from 'src/api';
import AirVentIcon from 'src/assets/air-vent.svg?react';
import GarageIcon from 'src/assets/garage.svg?react';
import HomeOuterIcon from 'src/assets/home-outer.svg?react';
import { useAssessmentContext } from 'src/contexts/AssessmentContext';

// eslint-disable-next-line react-refresh/only-export-components
const AssessmentFormSectionsIcon: Record<string, React.ElementType> = {
  user_circle: UserCircleIcon,
  camera: CameraIcon,
  home: HomeIcon,
  car: GarageIcon,
  garage: GarageIcon,
  car_house: GarageIcon,
  home_outer: HomeOuterIcon,
  air_vent: AirVentIcon,
  light_bulb: LightBulbIcon,
};

const resolveSubsectionDependencies = (
  subsections: AssessmentTemplateContentSectionSubSection[],
  allResults: Record<string, AssessmentResult>
) => {
  return subsections.reduce<AssessmentTemplateContentSectionSubSection[]>(
    (acc, c) => {
      if (c.depends_on && c.depends_on_value) {
        const result = allResults[c.depends_on];
        const hasValue =
          result?.value === c.depends_on_value ||
          result?.values.some((r) => r.value === c.depends_on_value);

        if (!hasValue) {
          return acc;
        }
      }

      acc.push(c);

      return acc;
    },
    []
  );
};

const getSectionProgress = (
  subsections: AssessmentTemplateContentSectionSubSection[],
  results: Record<string, AssessmentResult>
): {
  status: AssessmentFormSectionStatus;
  percentage: number;
  answered: number;
  totalCount: number;
  notAnsweredSectionsNames: string[];
} => {
  const { answered, totalCount, notAnsweredSectionsNames } =
    subsections.reduce<{
      answered: number;
      totalCount: number;
      notAnsweredSectionsNames: string[];
    }>(
      (acc, subsection) => {
        subsection.questions?.forEach((q, _, arr) => {
          const dependingQuestion = q.depends_on
            ? arr[q.depends_on - 1]
            : undefined;
          const dependingResult = dependingQuestion
            ? results[dependingQuestion.question_id]
            : undefined;

          const dependsOnValues = Array.isArray(q.depends_on_value)
            ? q.depends_on_value
            : q.depends_on_value
              ? [q.depends_on_value]
              : [];

          if (
            (results[q.question_id] &&
              (results[q.question_id].value ||
                !!results[q.question_id].values.length)) ||
            dependsOnValues.every((dov) => dov !== dependingResult?.value)
          ) {
            acc.answered += 1;
          } else {
            acc.notAnsweredSectionsNames.push(subsection.header);
          }

          acc.totalCount += 1;
        });

        subsection.core_data_fields?.forEach((q) => {
          if (results[q] && (results[q].value || !!results[q].values.length)) {
            acc.answered += 1;
          }

          acc.totalCount += 1;
        });

        return acc;
      },
      { answered: 0, totalCount: 0, notAnsweredSectionsNames: [] }
    );

  return {
    status:
      answered === totalCount
        ? 'complete'
        : answered
          ? 'incomplete'
          : 'not-started',
    percentage: totalCount ? answered / totalCount : 0,
    answered,
    totalCount,
    notAnsweredSectionsNames,
  };
};

const getPrevSectionSearchParams = (
  prevSection?: AssessmentTemplateContentSection
):
  | {
      currentSection: string;
      currentSubsection: string;
    }
  | undefined => {
  if (!prevSection) return;

  const lastSubsection =
    prevSection.subsections[prevSection.subsections.length - 1];

  if (!lastSubsection) return;

  return {
    currentSection: prevSection.section_name,
    currentSubsection:
      lastSubsection.section_order +
      lastSubsection.header +
      (lastSubsection.repeats_on ? '0' : ''),
  };
};

export const useAssessmentFormSections = () => {
  const {
    details: {
      template: { template_sections },
      results,
    },
  } = useAssessmentContext();
  const { getAssessmentFormAndSubmitSection } =
    useAssessmentFormReviewAndSubmitSection();

  const { sectionNames, sections } = useMemo(() => {
    const _sections = template_sections.reduce<{
      sectionNames: AssessmentFormSection[];
      sections: AssessmentFormSectionContentByName;
    }>(
      (a, c, idx, arr) => {
        const sectionProgress = getSectionProgress(c.subsections, results);

        const iconComponent =
          AssessmentFormSectionsIcon[c.section_cover.icon] ??
          QuestionMarkCircleIcon;

        const nextSection =
          idx < arr.length - 1 ? arr[idx + 1].section_name : undefined;
        const prevSection = idx > 0 ? arr[idx - 1] : undefined;

        const subsections = resolveSubsectionDependencies(
          c.subsections,
          results
        );

        a.sectionNames.push({
          id: c.section_name,
          ...sectionProgress,
        });
        a.sections[c.section_name] = {
          idx: c.section_order.toString().padStart(2, '0'),
          title: c.section_name,
          cover: {
            ...c.section_cover,
            useCase: c.section_cover.matters_text,
            iconComponent,
          },
          canPrevSection: !!prevSection,
          prevSection: prevSection?.section_name,
          prevSectionSearchParams: getPrevSectionSearchParams(prevSection),
          nextSection,
          canNextSection: !!nextSection,
          subsections,
        };

        return a;
      },
      { sectionNames: [], sections: {} }
    );

    const lastSection =
      _sections.sectionNames[_sections.sectionNames.length - 1];

    if (lastSection) {
      _sections.sections[lastSection.id].nextSection = 'summary';
      _sections.sections[lastSection.id].canNextSection = true;
    }

    const { sectionContent, sectionName } = getAssessmentFormAndSubmitSection(
      _sections.sectionNames,
      _sections.sections,
      lastSection
    );

    _sections.sectionNames.push(sectionName);
    _sections.sections.summary = sectionContent;

    return _sections;
  }, [results, template_sections, getAssessmentFormAndSubmitSection]);

  const [currentSection, setCurrentSection] = useState<string | undefined>(
    template_sections[0]?.section_name
  );

  return {
    sectionNames,
    sections,
    currentSection,
    setCurrentSection: (str: string) => setCurrentSection(str),
  };
};
