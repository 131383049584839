import React from 'react';
import { useSearchParams } from 'react-router-dom';

import AssessmentFormButtons from './AssessmentFormButtons';
import AssessmentFormCoreFields from './AssessmentFormCoreFields';
import AssessmentFormIntro from './AssessmentFormIntro';
import AssessmentFormQuestions from './AssessmentFormQuestions';
import { AssessmentFormSectionContent } from './hooks/types';
import { useAssessmentFormSubSections } from './hooks/useAssessmentFormSubSections';
import Text from 'src/components/Typography';
import { useAssessmentContext } from 'src/contexts/AssessmentContext';

interface Props {
  currentSection: AssessmentFormSectionContent;
  canPrevSection: boolean;
  canNextSection: boolean;
}

const AssessmentFormSection: React.FC<Props> = ({ currentSection }) => {
  const [, setSearchParams] = useSearchParams();
  const {
    details: { assessmentId, questions, propertyId, userId, results },
  } = useAssessmentContext();
  const {
    currentSubsection,
    onNextSubsection,
    onPreviousSubsection,
    subsections,
  } = useAssessmentFormSubSections(currentSection, results);

  const subsection = currentSubsection
    ? subsections[currentSubsection]
    : undefined;

  const canDoPrev =
    !!subsection?.newPrevSubsection || !!currentSection.prevSection;

  return (
    <>
      <div className='flex flex-grow flex-col gap-2 py-4'>
        {subsection?.header === 'cover' && currentSection.cover && (
          <AssessmentFormIntro
            heading={currentSection.idx}
            title={currentSection.cover.header}
            icon={currentSection.cover.iconComponent}
            description={currentSection.cover.body}
            descriptionLabel={currentSection.cover.subheader}
            useCase={currentSection.cover.useCase}
          />
        )}
        {subsection && subsection.header !== 'cover' && (
          <>
            <Text as='h2' size='lg' className='mx-4 font-semibold'>
              {subsection.header}
            </Text>
            <Text className='mx-4 mb-4'>{subsection.description}</Text>
            {!!subsection.coreDataFields.length && (
              <AssessmentFormCoreFields
                propertyId={propertyId}
                userId={userId}
                coreFields={subsection.coreDataFields}
                results={results}
              />
            )}
            {!!subsection.questions.length && (
              <AssessmentFormQuestions
                assessmentId={assessmentId}
                questions={subsection.questions}
                allQuestions={questions}
                results={results}
                sectionType={subsection.sectionType}
                resultIndex={subsection.resultIndex}
              />
            )}
          </>
        )}
      </div>
      <AssessmentFormButtons
        canPreviousStep={canDoPrev}
        disableNextStep={false}
        nextLabel={subsection?.nextButtonLabel}
        onNextStep={() => {
          if (!subsection) return;

          if (subsection.overrideNextButtonAction) {
            subsection.overrideNextButtonAction();

            return;
          }

          onNextSubsection();
        }}
        onPreviousStep={() => {
          if (!subsection) return;
          if (currentSection.prevSectionSearchParams) {
            setSearchParams({
              currentSection:
                currentSection.prevSectionSearchParams.currentSection,
              currentSubsection:
                currentSection.prevSectionSearchParams.currentSubsection,
            });
          }

          onPreviousSubsection();
        }}
      />
    </>
  );
};

export default AssessmentFormSection;
