import { useQuery } from '@tanstack/react-query';

import { useApiClient } from 'src/api/hooks/useApiClient';
import { LookupDetails, Paginated } from 'src/api/types';

export interface UseGetAssessmentQuestionLookupDetailsParams {
  assessmentId: string;
  questionId: string;
  lookupTable: string;
  orderBy: string;
  lookupColumn?: string;
  lookupValue?: string;
}

export const useGetAssessmentQuestionLookupDetails = (
  params: UseGetAssessmentQuestionLookupDetailsParams
) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: [
      'assessment-lookup-value',
      {
        lookupColumn: params.lookupColumn,
        lookupTable: params.lookupTable,
        lookupValue: params.lookupValue,
        orderBy: params.orderBy,
      },
    ] as const,
    queryFn: async ({
      queryKey: [, { lookupColumn, lookupTable, lookupValue, orderBy }],
    }) => {
      const client = await getClient();

      const search = new URLSearchParams({
        lookupTable,
        orderBy,
      });

      if (lookupValue && lookupColumn) {
        search.set('lookupValue', lookupValue);
        search.set('lookupColumn', lookupColumn);
      }

      const response = await client.get<Paginated<LookupDetails>>(
        `assessments/${params.assessmentId}/questions/${params.questionId}/lookup`,
        {
          params: search,
        }
      );

      return response.data;
    },
  });
};
